import $ from 'jquery'
import Block from '../../common.blocks/block'

export default class SetInfo extends Block {
	static title = 'set-info'

	constructor($el) {
		super($el)

		this.dataType = 'product'
		this.sets = this.$el.findElement('product')

		this.sets.each((index, element) => {
			$(element).on('submit', (event) => this.handleSubmit(event, element))
		})
	}

	handleSubmit(event, element) {
		event.preventDefault()

		const select = $(element).find('select').children('option:selected')

		this.data = new FormData($(element).get(0))
		this.data.append('ajax', true)
		this.data.append('size_id', select.val())
		this.data.append('color_id', select.data('colorId'))
		this.data.append('count', 1)

		// color and size are required
		if (
			this.data.has('color_id') === false ||
			this.data.has('size_id') === false
		) {
			$(document).trigger('message:error', this.$el.data('validationLabel'))
			return null
		}

		this.setLoading(true, element)

		this.ga4Data = {
			item_id: $(element).data('productId'),
			color: select.val(),
			size: select.data('colorId'),
			item_name: $(element).data('productName'),
			item_brand: $(element).data('productBrand'),
			item_category: $(element).data('productCategory'),
			price: $(element).data('productPrice'),
			quantity: 1,
		}

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data: this.data,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					$(document).trigger('product:add', json)
					$(document).trigger('shoppingcart:set-add-to-cart', this.ga4Data)
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
			.always(() => this.setLoading(false, element))
	}

	setLoading(loading, element) {
		if (loading) {
			$(element).find('.button').addModifier('loading')
		} else {
			$(element).find('.button').removeModifier('loading')
		}
	}
}

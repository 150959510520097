import $ from 'jquery'
import { checkVAT, netherlands, germany, belgium } from 'jsvat'

import Block from '../../common.blocks/block'

export default class Register extends Block {
	static title = 'register'

	constructor($el) {
		super($el)

		this.country = this.$el.find('select[name="country_id"]')
		this.vatField = this.$el.findElement('vat-field')
		this.message = this.$el.findElement('vat-message')
		this.vatInput = this.$el.findElement('vat-input')

		this.country.on('change', () => {
			this.vatField.val('')
			this.vatInput.val('')
			this.message.removeModifier('active')
		})

		this.password = this.$el.findElement('password')
		this.$el.findElement('submit-as-user').on('click', () => {
			this.password.attr('required', true)
		})
		this.$el.findElement('submit-as-guest').on('click', () => {
			this.password.attr('required', false)
		})

		this.b2bFields = this.$el.findElement('b2b')
		this.checkedInput = $('input[name="groups[]"]:checked').val()

		if (this.checkedInput == 2) {
			this.toggleB2b(true)
		}

		$('input[name="groups[]"]').on('change', () => {
			if ($('input[name="groups[]"]:checked').val() == 2) {
				this.toggleB2b(true)
			} else {
				this.toggleB2b(false)
			}
		})

		// VAT validation
		this.vatField.on('change', () => {
			this.validateVAT()
		})
	}

	validateVAT() {
		this.$el.find('button[type="submit"]').prop('disabled', true)
		if (
			!this.vatField.length ||
			!this.vatField.val() ||
			this.vatField.is(':disabled')
		) {
			this.$el.find('button[type="submit"]').prop('disabled', false)
			this.vatInput.val('')
			return
		}

		if (
			this.country.val() != 150 &&
			this.country.val() != 21 &&
			this.country.val() != 80
		) {
			this.$el.find('button[type="submit"]').prop('disabled', false)
			this.vatInput.val(this.vatField.val())
			return
		}

		const { isValid } = this.checkVAT()
		if (isValid) {
			this.$el.find('button[type="submit"]').prop('disabled', false)
			this.vatInput.val(this.vatField.val())
			this.message.removeModifier('active')
			return
		}

		this.message.addModifier('active')
		if (this.country.val() == 150) {
			this.message.text(this.message.data('nlVat'))
		} else if (this.country.val() == 21) {
			this.message.text(this.message.data('beVat'))
		} else if (this.country.val() == 80) {
			this.message.text(this.message.data('deVat'))
		}
	}

	checkVAT() {
		return checkVAT(this.vatField.val(), [netherlands, germany, belgium])
	}

	toggleB2b(b2b) {
		if (b2b) {
			this.b2bFields.removeModifier('hidden')
			$('input[name^="properties"]').prop('disabled', false)
			$('input[name^="properties"]')
				.parent()
				.removeClass('mdc-text-field--disabled')
		} else {
			this.b2bFields.addModifier('hidden')
			$('input[name^="properties"]').prop('disabled', true)
			$('input[name^="properties"]')
				.parent()
				.addClass('mdc-text-field--disabled')
		}
	}
}

import $ from 'jquery'

import Block from '../../common.blocks/block'

export default class SizeSelect extends Block {
	static title = 'size-select'

	constructor($el) {
		super($el)

		this.dropdown = this.$el.findElement('dropdown')
		this.stockIndicator = this.$el.findElement('stock')

		// if an user selects a new color, we want to display the corresponding size select
		$(document).on('color:change', (event, data) =>
			this.handleColorChange(event, data),
		)

		if (!this.$el.hasClass('size-select--hidden')) {
			this.handleChange()
		}

		this.dropdown.change(() => this.handleChange())

		if (!this.$el.hasModifier('hidden')) {
			this.handleStockIndicatorChange()
		}
	}

	handleColorChange(event, data) {
		if (data.id == this.$el.data('colorId')) {
			this.$el.removeModifier('hidden')
			this.dropdown.prop('disabled', false)
		} else {
			this.$el.addModifier('hidden')
			this.dropdown.prop('disabled', true)
		}

		if (!this.$el.hasModifier('hidden')) {
			this.handleChange()
		}
	}

	handleChange() {
		const selected = this.dropdown.find(':selected')

		$(document).trigger('size:change', {
			// id: event.target.value,
			id: selected.val(),
			name: selected.data('title'),
			stock: selected.data('stock'),
			stock_store: selected.data('stock-store'),
			price: selected.data('price'),
			price_advice: selected.data('price-advice'),
			price_save: selected.data('price-save'),
			credit: selected.data('credit'),
			remove: selected.is(':checked'),
			availability: selected.data('availability'),
			sku: selected.data('sku'),
			outlet: selected.data('outlet'),
			option_id: selected.data('optionId'),
			option_time: selected.data('optionTime'),
			// remove: !event.target.checked,
		})

		this.handleStockIndicatorChange()
	}

	handleStockIndicatorChange() {
		const stockValue = this.dropdown.find(':selected').data('stock')
		if (
			this.dropdown.find(':selected').data('availability') == 'available' &&
			stockValue < 1
		) {
			const stockText = this.stockIndicator.data('available')
			this.stockIndicator.text(stockText)
			$(document).trigger('product:inStock', {
				status: true,
			})
		} else if (
			this.dropdown.find(':selected').data('availability') == 'presale' &&
			this.dropdown.find(':selected').data('optionTime')
		) {
			const stockText = this.stockIndicator.data('presale')
			this.stockIndicator.text(stockText)
			$(document).trigger('product:inStock', {
				status: true,
			})
		} else if (
			this.dropdown.find(':selected').data('availability') == 'available_soon'
		) {
			const stockText = this.stockIndicator.data('availableSoon')
			this.stockIndicator.text(stockText)
			$(document).trigger('product:inStock', {
				status: false,
			})
		} else if (stockValue > 0) {
			if (stockValue === 1) {
				const stockText = this.stockIndicator.data('lastItem')
				this.stockIndicator.text(stockText)
				$(document).trigger('product:inStock', {
					status: true,
				})
			} else {
				const stockText = this.stockIndicator.data('inStock')
				this.stockIndicator.text(stockText)
				$(document).trigger('product:inStock', {
					status: true,
				})
			}
		} else {
			const stockText = this.stockIndicator.data('soldOut')
			this.stockIndicator.text(stockText)
			$(document).trigger('product:inStock', {
				status: false,
			})
		}
	}
}

import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class StockStatus extends Block {
	static title = 'stock-status'

	constructor($el) {
		super($el)

		// set this to true to use 09:00 hour for testing
		this.debug = false

		this.date = new Date()
		this.message = this.$el.findElement('text')
		this.arrow = this.$el.findElement('arrow')

		// language type for date formatting
		switch (this.$el.data('language')) {
			case 1:
				this.language = 'EN-gb'
				break

			case 3:
				this.language = 'DE-de'
				break

			case 4:
				this.language = 'FR-fr'
				break

			case 2:
			default:
				this.language = 'NL-nl'
				break
		}

		// own locations
		this.own_locations = [1, 2]

		// try to get json translated text
		try {
			this.translations = JSON.parse($('.delivery-times').text())
		} catch (e) {
			this.translations = null
		}

		// product page
		if (this.$el.hasModifier('product')) {
			$(document).on('size:change', (event, data) =>
				this.checkStock(event, data),
			)
		}

		// shoppingcart
		if (this.$el.hasModifier('shoppingcart')) {
			// because of page refresh does not need to change the stock indicator on size or count change
		}

		// shoppingcart total
		if (this.$el.hasModifier('total')) {
			// because of page refresh does not need to change the stock indicator on size or count change
		}

		// product widgets
		if (this.$el.hasModifier('widget')) {
			$(document).on('product:change_size', (event, data) => {
				if (data.product == this.$el.data('productId')) {
					this.checkStock(event, data)
				}
			})
		}
	}

	checkStock(event, data) {
		this.setLoading(true)
		let formdata = new FormData()
		formdata.append('ajax', true)
		formdata.append('stock_check', true)
		formdata.append('shoppingcart_id', this.$el.data('shoppingcartId'))
		formdata.append('product_id', this.$el.data('productId'))
		formdata.append('option_id', data.option_id)
		formdata.append('count', data.count ? data.count : 1)
		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data: formdata,
		})
			.done((json) => {
				this.changeStatus(json)
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
	}

	changeStatus(data) {
		this.setLoading(false)
		if (data.stock_check.delivery_date == 'own_stock') {
			this.message.text(this.nextDayText(this.$el.data('todayStock')))
			this.changeIcons('check', 'success')
		} else if (data.stock_check.delivery_date == 'unknown') {
			this.message.text(this.$el.data('deliveryUnkown'))
			this.changeIcons('cross', 'warning')
		} else if (data.stock_check.delivery_date == 'unavailable') {
			if (this.$el.data('outlet')) {
				this.message.text(this.$el.data('outletNoStock'))
			} else {
				this.message.text(this.$el.data('noStock'))
			}
			this.changeIcons('cross', 'error')
		} else {
			if (data.stock_check.delivery_date_type == 'timestamp') {
				const delivery_time = data.stock_check.delivery_date.split('-')
				// const timestampInMilliSeconds = data.stock_check.delivery_date * 1000
				// const date = new Date(timestampInMilliSeconds)

				// const formattedDate = date.toLocaleDateString(this.language, {
				// 	weekday: 'long',
				// 	day: 'numeric',
				// 	month: 'long',
				// })

				// const key =
				// 	data.stock_check.delivery_date_type == 'timestamp_future'
				// 		? 'available'
				// 		: 'shipped'
				// const shipped_text = this.translations[key]
				// const text = shipped_text + ' ' + formattedDate

				// data.stock_check.delivery_date_type == 'timestamp_future'
				// 	? this.changeIcons('cross', 'warning')

				let timestampText
				if (delivery_time[0] == 'mid') {
					timestampText =
						this.translations['shipped'] +
						' ' +
						this.translations[delivery_time[0]].toLowerCase() +
						this.translations[delivery_time[1]] +
						' ' +
						delivery_time[2]
				} else {
					timestampText =
						this.translations['shipped'] +
						' ' +
						this.translations[delivery_time[0]].toLowerCase() +
						' ' +
						this.translations[delivery_time[1]] +
						' ' +
						delivery_time[2]
				}

				this.changeIcons('check', 'success')
				this.message.text(timestampText)
			} else {
				this.message.text(
					this.nextDayText(
						this.translations[data.stock_check.delivery_date_numeric],
						'longtail',
					),
				)
				this.changeIcons('check', 'success')
			}
		}
	}

	nextDayText(message, type = null) {
		let startToday = 0
		let endToday = 20 * 60 + 30
		let startTomorrow = 20 * 60 + 31
		let endTomorrow = 23 * 60 + 29
		if (this.debug) {
			startToday = 0
			endToday = 9 * 60 - 1
			startTomorrow = 9 * 60
			endTomorrow = 23 * 60 + 59
		}
		// Time delivery today
		const endTodayString = '20:30'

		const now = this.date.getHours() * 60 + this.date.getMinutes()
		const day = this.date.getDay()

		if (type == 'longtail') {
			/**
			 * The code below was a feature requested by de Kroo and later removed on their request.
			 * Commented out because it might be used again in the future
			 */
			// if (now >= startTomorrow && now <= endTomorrow) {
			//	// eslint-disable-next-line no-useless-escape
			// 	const numbers = message.match(/(\d[\d\.]*)/g)

			// 	const intNumbers = numbers.map(Number).reverse()

			// 	intNumbers.forEach((number) => {
			// 		const newNumber = parseInt(number) + 1
			// 		message = message.replace(number, newNumber)
			// 	})
			// 	return message
			// } else {
			// 	return message
			// }

			return message
		} else {
			if (day < 6) {
				if (day == 5) {
					if (now >= startTomorrow && now <= endTomorrow) {
						message = this.$el.data('ownStock')
						return message.replace('%%', this.$el.data('nextmonday'))
					}
				}

				if (now >= startToday && now <= endToday) {
					return message.replace('%%', endTodayString)
				}

				if (now >= startTomorrow && now <= endTomorrow) {
					message = this.$el.data('ownStock')
					return message.replace('%%', this.$el.data('nextday'))
				}
			}

			if (day == 6) {
				message = this.$el.data('ownStock')
				return message.replace('%%', this.$el.data('nextmonday'))
			}

			if (day == 7) {
				message = this.$el.data('ownStock')
				return message.replace('%%', this.$el.data('nextmonday'))
			}
		}
	}

	changeIcons(type, status) {
		const icon = this.$el.findElement('icon')
		if (type == 'check') {
			$(icon).removeClass('fa-xmark')
			$(icon).addClass('fa-check')
			this.changeStatusColor(status)
		}

		if (type == 'cross') {
			$(icon).removeClass('fa-check')
			$(icon).addClass('fa-xmark')
			this.changeStatusColor(status)
		}
	}

	changeStatusColor(type) {
		if (type == 'success') {
			this.$el.removeModifier('warning')
			this.$el.removeModifier('error')

			this.$el.addModifier('success')
		}

		if (type == 'warning') {
			this.$el.removeModifier('success')
			this.$el.removeModifier('error')

			this.$el.addModifier('warning')
		}

		if (type == 'error') {
			this.$el.removeModifier('success')
			this.$el.removeModifier('warning')

			this.$el.addModifier('error')
		}
	}

	getNextDays(days) {
		return new Date(
			this.date.getFullYear(),
			this.date.getMonth(),
			this.date.getDate() + days,
		).toLocaleDateString(this.language, {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
		})
	}

	setLoading(bool) {
		if (bool) {
			this.$el.findElement('icon').css('display', 'none')
			this.$el.findElement('loader').css('display', 'block')
		} else {
			this.$el.findElement('icon').css('display', 'block')
			this.$el.findElement('loader').css('display', 'none')
		}
	}
}
